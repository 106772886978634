import { Component, Vue } from 'vue-property-decorator';
import { VueTyper } from 'vue-typer';
import VueScrollTo from 'vue-scrollto';
import ContentSection from '../content-section.vue';

Vue.use(VueScrollTo);

import './home.scss';

@Component({
  template: require('./home.html'),
  components: {
      'vue-typer': VueTyper,
      ContentSection
  }
})
export class Home extends Vue {
    open_external(link: string): void {
        window.open(link, '_blank');
    }

    get logoImage() {
        return require('assets/images/logo.svg');
    }

    get projectsImage() {
        return require('assets/images/projects.jpg');
    }
}
