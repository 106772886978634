









import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class ContentSection extends Vue {
    @Prop(String)
    backgroundImage: string;

    @Prop(Boolean)
    rtl: boolean;

    @Prop(Boolean)
    skewedBackground: boolean;

    @Prop(Boolean)
    noBackground: boolean;

    get backgroundImagePath(): string {
        return require(`assets/images/${this.backgroundImage}`);
    }

    mounted() {
    }
}
