var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "content-section" }, [
    _vm.backgroundImage && !_vm.noBackground
      ? _c("div", {
          directives: [
            {
              name: "lazy",
              rawName: "v-lazy:background-image",
              value: _vm.backgroundImagePath,
              expression: "backgroundImagePath",
              arg: "background-image"
            }
          ],
          staticClass: "header-background",
          class: { "skewed-header-background": _vm.skewedBackground }
        })
      : !_vm.noBackground
        ? _c("div", {
            staticClass: "header-background",
            class: { "skewed-header-background": _vm.skewedBackground }
          })
        : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content-section-content", class: { rtl: _vm.rtl } },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }